import { Injectable } from '@angular/core';
import { HttpClient } from  '@angular/common/http';
import { Observable } from 'rxjs';
import * as globalVars from '../api_settings/settings';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  SERVER_URL: string = 'https://'+globalVars.awsAPI +'.execute-api.'+globalVars.awsREGION +'.amazonaws.com/'+globalVars.awsSTAGE+'/uploadIcons';

  constructor(private http: HttpClient) { }

  deleteImage(fileName: string, folderPath: string): Observable<Object> {
    const x = { 'folderPath': folderPath, 'filename': fileName, 'delete': 'true'};
    return this.http.post(this.SERVER_URL, x, globalVars.httpOptions);
  }


  uploadImage(image: any, fileName: string, folderPath: string, currentFile = null): Observable<Object>  {
    let x: any;
    if (currentFile != null) {
      x = { 'folderPath': folderPath, 'filename': fileName, 'file': image, 'currentFile': currentFile};
    } else {
      x = { 'folderPath': folderPath, 'filename': fileName, 'file': image};
    }
    return this.http.post(this.SERVER_URL, x, globalVars.httpOptions);
  }

}
