import {Subject, Subscription, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as globalVars from '../../api_settings/settings';
import { selectedarrays, filterarrays } from '../../../wpt/analytics/quicksight/quicksight'

@Injectable({
  providedIn: 'root',
})
export class FilteroptionsService {
  // QS stuff
  firstlistchange= new Subject<any>();
  secondlistchange= new Subject<any>();
  // LEAFLET MAP-CONTROL STUFF
  arrays: selectedarrays = {};
  selectedarrays: BehaviorSubject<any> = new BehaviorSubject(this.arrays);

  firstfilter: filterarrays = {};
  arraychange: BehaviorSubject<any> = new BehaviorSubject(this.firstfilter);
  
  constructor(private http: HttpClient) {}

  getfilteroptions(startres,endres,priority,second, third, fourth){
    let url = 'https://'+globalVars.awsAPI +'.execute-api.'+globalVars.awsREGION +'.amazonaws.com/'+globalVars.awsSTAGE+'/filteroptions?'+startres+endres+'&priority='+priority+'&second='+second+'&third='+third+'&fourth='+fourth;
    return new Promise((resolve, reject) => {
      let x = this.http.get(url, globalVars.httpOptions).subscribe((resq: any) => {
        this.updatedarrays('first', resq['result'][0]['priority'])
        this.updatedarrays('second', resq['result'][1]['second'])
        this.updatedselected('datestart', startres)
      this.updatedselected('dateend', endres)
        x.unsubscribe();
        console.log('resolving from api call')
        resolve(resq);
      },
      error => {
        console.log(error);
        x.unsubscribe();
        reject(error);
      });
    });
  }

  updatedarrays(type, value){
    console.log('in updatearrays for ' + type)
    console.log(this.firstfilter.first)
    try{
        if (type == 'reset'){
            this.firstfilter = {}
            this.arraychange.next(null)
        }
        if (type == 'first'){
            this.firstfilter.first = [value]; 
            console.log(this.firstfilter.first)
            this.arraychange.next(this.firstfilter)
        }
        if (type == 'second'){
            this.firstfilter.second = [value]; 
            this.arraychange.next(this.firstfilter)
        }
        if (type == 'secondfiltered'){
          let list = []
          let FCcount = 0
          let gamecount = 0
          let preppedlist = []
          value.forEach(game => {
            gamecount += 1
            let index = this.firstfilter.second[0].findIndex((result)=>{
              return result['first'] == game
            })
            if (index !== -1){
              let casinolist = this.firstfilter.second[0][index]['second']
              casinolist.forEach(casino => {
                if (list.indexOf(casino) ==-1 || list.length == 0){
                  list.push(casino)
                  preppedlist.push({'id':casino})
                }
              });
              FCcount += 1
            }else{
              FCcount += 1
            }
          })
          if (FCcount == value.length){
            this.firstfilter.secondfiltered = preppedlist
            this.arraychange.next(this.firstfilter)
          }
        }
        
    }catch(err){
        console.log('ERR SERVICE FILE QS')
        console.log(err);
    }
  }


  updatedselected(type, value){
    console.log('in updatedselected')
    try{
        if (type == 'reset'){
            this.selectedarrays.next(null)
            return
        }
        if (type == 'datestart'){
          this.arrays.ds = value
          console.log(this.arrays.ds)
        }
        if (type == 'dateend'){
          this.arrays.de = value
          console.log(this.arrays.de)
        }
        if (type == 'first'){
            this.arrays.first = [value]; 
        }
        if (type == 'second'){
            this.arrays.second = [value]; 
        }
        if (type == 'third'){
            this.arrays.third = [value]; 
        }
        if (type == 'fourth'){
            this.arrays.fourth = [value]; 
        }
        this.selectedarrays.next(this.arrays)
    }catch(err){
        console.log('ERR QS.SERVICE UPDATE SELECTED')
        console.log(err);
    }
    
}

  secondfilteroptions_params(startres,endres,priority,second, third, fourth){
    return new Promise((resolve, reject) => {
    let url = 'https://'+globalVars.awsAPI +'.execute-api.'+globalVars.awsREGION +'.amazonaws.com/'+globalVars.awsSTAGE+'/filteroptions?'+startres+endres+'&priority='+priority+'&second='+second+'&third='+third+'&fourth='+fourth;
      let x = this.http.get(url, globalVars.httpOptions).subscribe((resq: any) => {
        console.log('API INFO IS HERE //////////////')
        console.log(url)
        try{
          if (third != ''){
            this.secondlistchange.next(resq);
            x.unsubscribe();
            resolve();
          }else{
            this.firstlistchange.next(resq);
            x.unsubscribe();
            resolve();
          }
        }catch(err){
          console.log('ERR IN HERE')
          console.log(err);
          reject(err)
        }
      },
      error => {
        x.unsubscribe();
        console.log('ERR in MAP CONT SERVICE')
        console.log(error);
        reject(error);
      });
    });
  }
}

